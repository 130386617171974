<template>
    <sims-layout>

        <!-- Call For Help -->
        <div class="card">
            <div class="header">
                <div class="flex-wrapper">
                    <div class="left">
                        <h3>Call For Help</h3>
                    </div>
                    <div class="right">
                        <a-button @click.prevent="$router.push('/sims/incidents')" icon="left" type="primary">Back
                            To Incidents</a-button>
                    </div>
                </div>
            </div>
            <div class="body">

                <!-- Org selector and switch -->
                <div class="flex-wrapper">
                    <div class="left">
                        <organisation-selector></organisation-selector>
                    </div>
                    <div class="right">
                        <div class="actions" v-if="!isDemoModeOff">
                            <span class="is-demo-label">Demo</span>
                            <a-switch :disabled="isDemoModeAlways||isDemoModeNone" v-model="is_demo" />
                        </div>
                    </div>
                </div>
                <!-- / Org selector and switch -->

                <!-- If org selected -->
                <div class="incident-type-selector-wrapper" v-if="selectedOrganisation">
                    <incident-type-selector></incident-type-selector>
                </div>
                <!-- / If org selected -->

            </div>
        </div>
        <!-- / Call For Help -->

    </sims-layout>
</template>

<script>
import simsLayout from "./simsLayout.vue";
import OrganisationSelector from "../CallForHelp/OrganisationSelector.vue";
import IncidentTypeSelector from "./CallForHelp/IncidentTypeSelector.vue";
import { mapGetters, mapActions } from "vuex";
export default {
    components: { simsLayout, OrganisationSelector, IncidentTypeSelector },
    computed: {
        ...mapGetters('helpCaller', {
            selectedOrganisation: "selectedOrganisation",
            isDemo: "isDemo",
            isDemoModeOff: "isDemoModeOff",
            isDemoModeNone: "isDemoModeNone",
            isDemoModeAlways: "isDemoModeAlways",
            isDemoModeOptional: "isDemoModeOptional"
        }),

        is_demo: {
            set(val) {
                if (this.isDemoModeOptional) {
                    this.setIsDemo(val);
                }
            },
            get() {
                return this.isDemo;
            },
        },
    },
    methods: {
        ...mapActions("helpCaller", {
            setIsDemo: "setIsDemo",
        }),
    },
}
</script>

<style scoped lang="scss">
.is-demo-label {
    margin-right: 10px;
}

.incident-type-selector-wrapper {
    margin-top: 20px;
}

.flex-wrapper {
    display: flex;
    width: 100%;

    .left {
        flex-grow: 1;
        display: flex;
        align-items: center;
    }

    .right {
        flex-shrink: 1;
        display: flex;
        align-items: center;
    }
}
</style>